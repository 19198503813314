import { container, primaryColor, defaultFont } from "../../assets/global";


const footerStyle = theme=>( {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    display:"flex",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container:{
    ...container,
    display:"flex",
    flexFlow:"row wrap",
    justifyContent:"center",
    alignItems:"center",
    padding:"0",
    fontSize:"0.5rem !important",
    "@media (min-width:1024px)":{
      flexFlow:"row nowrap",
      justifyContent:"space-between",
      fontSize:"unset !important"
    }
  },
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit"
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    overflow:"hidden",
    "&:hover":{
      "& span":{
      transform: 'translate(0,0)',
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5"
      }
    }
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  }
});
export default footerStyle;
